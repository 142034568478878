<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view/>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

<!-- 
    how can I download the specific div as image file when btn click in vue js
    https://techformist.com/save-div-image-vue/ 

    https://www.npmjs.com/package/html2canvas
    
    *********************************************************************************************
    
    boxplot
    horizontalBoxplot in chartjs
    https://github.com/sgratzl/chartjs-chart-boxplot
    https://codesandbox.io/p/sandbox/boxplot-in-a-table-multiple-rows-nyi0o0?file=%2Fsrc%2Fcomponents%2FTable.vue
    https://www.npmjs.com/package/@sgratzl/chartjs-chart-boxplot
    https://apexcharts.com/javascript-chart-demos/box-whisker-charts/horizontal-boxplot/
    https://www.npmjs.com/package/chartjs-chart-box-and-violin-plot
    https://github.com/datavisyn/chartjs-chart-box-and-violin-plot?tab=readme-ov-file
    https://stackoverflow.com/questions/70276829/chart-js-boxplot-adding-additional-reference-value
    https://www.zingchart.com/docs/chart-types/boxplot
-->
