import { createRouter, createWebHistory } from 'vue-router'
import Bar from '../views/Bar.vue'

const routes = [
  {
    path: '/',
    name: 'bar',
    component: Bar
  },
  {
    path: '/input-panel',
    name: 'inputPanel',
    component: () => import('../views/inputpanelView.vue')
  },
  {
    path: '/line-input',
    name: 'lineinput',
    component: () => import('../views/lineinputpanel.vue')
  },
  {
    path: '/histogram-input',
    name: 'histoinput',
    component: () => import('../views/histogramInputPanel.vue')
  },
  {
    path: '/pie-chart',
    name: 'piechart',
    component: () => import('../views/PieChart.vue')
  },
  {
    path: '/line-chart',
    name: 'linechart',
    component: () => import('../views/LineChart.vue')
  },
  {
    path: '/box-plot',
    name: 'boxPlot',
    component: () => import('../views/boxPlot.vue')
  },
  // {
  //   path: '/histogram',
  //   name: 'histogram',
  //   component: () => import('../views/Histogram.vue')
  // },
  {
    path: '/elementry',
    name: 'elementry',
    component: () => import('../views/elementry.vue')
  },
  {
    path: '/multiple-elementry',
    name: 'multipleElementry',
    component: () => import('../views/elementryMultiple.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/color-drag',
    name: 'color-drag',
    component: () => import('../views/color-drag.vue')
  },
  {
    path: '/simpleDrag',
    name: 'simpleDrag',
    component: () => import('../views/simpleDrag.vue')
  },
  {
    path: '/colorpicker',
    name: 'colorpicker',
    component: () => import('../views/colorpicker.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
