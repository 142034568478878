<template>
    <div class="parent-right">
        <div class="child-border">
            <!-- :class="activeChart == 'pie' ? 'typeActive' : ''"  -->
            <img src="../assets/image/bar.png" alt="" class="piechart" @click="selectType('bar')">
            <img src="../assets/image/group-line.png" alt="" class="piechart" @click="selectType('line')">
            <!-- <img src="../assets/image/group-histogram.png" alt="" class="piechart" @click="selectType('histogram')"> -->
            <img src="../assets/image/group-pie.png" alt="" class="piechart" @click="selectType('pie')">
            <img src="../assets/image/group-table.png" alt="" class="pieLenchart" @click="selectType('elementry')">
        </div>
    </div>
</template>

<script>
export default {
    name: 'chartType',
    data () {
        return {
        }
    },
    props: {
        activeChart: String
    },
    computed: {
        receivedType() {
            return this.$store.state.activeChart;
        }
    },
    watch: { 
    },
    mounted() {
    },
    methods: {
        selectType(val) {
            this.activeType = val
            this.defaultRandom = false
            this.$store.commit('setActiveBar', this.activeType);
            sessionStorage.setItem('activeType', this.activeType)

            if(val === 'bar') {
                this.$store.commit('setActiveBar', this.activeType);
                sessionStorage.setItem('activeType', this.activeType)

                // const minYAxisValue = this.firstNum + 0.00001;
                // const dataNo = this.getData.dataToSend.dataNo;
                // const dataColor = this.getData.dataToSend.dataColor;

                // let tempData = {
                //     dataToSend: { 
                //         title: this.title,
                //         hname: this.hName, 
                //         hunit: this.hUnit,
                //         vname: this.vName,
                //         vunit: this.vUnit,
                //         setNumFirst: this.firstNum,
                //         setNumLast: this.lastNum,
                //         groupDist: this.groupDist,
                //         labels: this.chartLabels,
                //         // dataNo: dataNo,
                //         // dataColor: this.colorArr,
                //         // totCols: this.displayRow
                //     }
                // }
                // console.log(tempData)

                this.$router.push('/');
            } else if(val === 'pie') {
                this.$router.push('/pie-chart');
            } else if(val === 'histogram') {
                this.$router.push('/histogram');
            } else if(val === 'line') {
                this.$router.push('/line-chart');
            } else if(val === 'elementry') {
                this.$router.push('/elementry');
            } 
        },
    }
}
    
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.parent-right {
    width: 20%;
    max-width: 147px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    // background: #000;
    // border: 1px solid #ddd;
    margin-left: 8px;
    align-self: flex-start;

    background: #febfb6;
    padding: 0 7px;
    border-radius: 30px;
    // border: 0px solid #febfb6;
    @media screen and (max-width: 768px) {
        min-width: 130px;
    }
    .child-border {
        background: #FFDBD0;
        padding: 1rem;
        border-radius: 25px;
        margin-bottom: 1.3rem;
        margin-top: 6px
    }

    .piechart {
        width: 117px;
        height: 130px;
        cursor: pointer;
        margin-bottom: 5px
    }
    .pieLenchart {
        width: 117px;
        height: 130px;
        cursor: pointer;
    }
    .typeActive {
        border: 7px solid #FFF;
        border-radius: 35px;
    }
}
</style>
