import { createStore } from 'vuex'

export default createStore({
  state: {
    inputData: null,
    histoData: null,
    activeChart: null,
    elementryData: null,
  },
  getters: {
  },
  mutations: {
    setInputData(state, newData) {
      state.inputData = newData;
    },
    setHistoData(state, newData) {
      state.histoData = newData;
    },
    setActiveBar(state, newData) {
      state.activeChart = newData;
    },
    setElementryData(state, newData) {
      state.elementryData = newData;
    }
  },
  actions: {
  },
  modules: {
  }
})
