<template>
  <div class="page-css">
    <!-- <h1>{{ msg }}</h1> -->
    <p class="store-title">南一書局</p>
    <div class="parent-block">
        <div class="parent-left">
            <!-- <div ref="printcontent" class="dl-block"> -->
            <div ref="captureElement" class="dl-block">
                <div class="unitdivl">
                    <!-- <div v-if="defaultRandom" class="horizontal-unit">
                        <span>{{vName}}</span>
                    </div>
                    <div v-else-if="activeType === ''" class="vertical-unit">
                        <span>名稱(單位)</span>
                    </div> -->
                    <div class="vertical-nounit">
                        <div class="inputBorder">
                            <input type="text" v-model="vName" placeholder="名稱" class="input-box1">
                            (<input type="text" v-model="vUnit" placeholder="單位" class="input-box2">)
                        </div>
                        <!-- <span >{{vName}}({{vUnit}})</span> -->
                    </div>
                </div>  
                <div class="canvas-container">
                    <!-- <canvas :style="getData !== 'nodata' ? 'display: none;' : ''" id="noChart" ref="myCanvas" width="400" height="150"></canvas> -->
                    <canvas id="myChart" ref="myRefChart" width="400" height="200"></canvas>
                    <div class="horizontal-nounit">
                        <div class="inputBorder">
                            <input type="text" v-model="hName" placeholder="名稱" class="input-box1">
                            (<input type="text" v-model="hUnit" placeholder="單位" class="input-box2">)
                        </div>
                    </div>
                </div>
                <div class="input-parent">
                    <input type="text" v-model="title" placeholder="標題" class="input-titlebox">
                </div>
                <div class="parent-row">
                    <div class="row-css">
                        <div v-for="(label, index) in chartLabels.length" :key="index" class="parent-input">
                            <input type="text" v-if="index !== (chartLabels.length - 1)" :id="'input-' + index" v-model="chartLabels[index]" @input="updateChart" class="eachinput">
                        </div>
                        <input type="color" ref="colorPicker" id="colorPicker" style="display: none;" class="color-picker">
                    </div>
                </div>
                <div v-if="showpicker" class="color-panel">
                    <div class="color-box" style="background-color: #ff0000;" @click="selectColor('#ff0000')"></div>
                    <div class="color-box" style="background-color: #00ff00;" @click="selectColor('#00ff00')"></div>
                    <div class="color-box" style="background-color: #0000ff;" @click="selectColor('#0000ff')"></div>
                    <div class="color-box" style="background-color: #ffff00;" @click="selectColor('#ffff00')"></div>
                    <div class="color-box" style="background-color: #ff00ff;" @click="selectColor('#ff00ff')"></div>
                    <div class="color-box" style="background-color: #00ffff;" @click="selectColor('#00ffff')"></div>
                    <div class="color-box" style="background-color: #ff9900;" @click="selectColor('#ff9900')"></div>
                    <div class="color-box" style="background-color: #9900ff;" @click="selectColor('#9900ff')"></div>
                    <div class="color-box" style="background-color: #0099ff;" @click="selectColor('#0099ff')"></div>
                    <div class="color-box" style="background-color: #ff0099;" @click="selectColor('#ff0099')"></div>
                    <div class="color-box" style="background-color: #99ff00;" @click="selectColor('#99ff00')"></div>
                    <div class="color-box" style="background-color: #00ff99;" @click="selectColor('#00ff99')"></div>
                </div>
            </div>
            <div class="one-row">
                <div class="left-div">
                    <!-- <div class="parent-bg">
                        <p class="data1" @click="routetoLine()">
                            輸入資料
                        </p>
                    </div> -->
                    <img src="../assets/image/input-data.png" alt="" class="input-img" @click="routetoLine">
                    <!-- @click="showhide" -->
                    <!-- <div class="parent2-bg">
                        <p class="data2" @click="toggleLabels">
                            <span v-if="showTopLabels">隱藏數字</span>
                            <span v-else>顯示數字</span>
                        </p>
                    </div> -->
                    <img v-if="showTopLabels" src="../assets/image/display-num.png" alt="" class="shownum" @click="toggleLabels">
                    <img v-else src="../assets/image/histonum.png" alt="" class="shownum" @click="toggleLabels">
                    
                    <div class="data3" @click="toggleChart">
                        <img v-if="!showHorizontal" src="../assets/image/horizontal.png" alt="" class="imgcss">
                        <img v-else src="../assets/image/vertical.png" alt="" class="imgcss">
                    </div>
                </div>
                <div class="right-div">
                    <img src="../assets/image/random.png" alt="" class="randomcss" @click="generateRandom">
                    <!-- <p class="data4" @click="generateRandom">
                        隨機出題
                    </p> -->
                    <img src="../assets/image/resetbtnimg.png" alt="" class="removecss" @click="resetAll">
                    <img src="../assets/image/camerabtnimg.png" alt="" class="cameracss" @click="captureElementAsImage">
                </div>
            </div>
        </div>
        <chartType :activeChart="activeType" />
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js/auto';
import 'chartjs-plugin-dragdata'

import 'spectrum-colorpicker'; // Import the Spectrum Colorpicker stylesheet
import 'spectrum-colorpicker/spectrum'; // Import the Spectrum Colorpicker script
import { shallowRef } from 'vue';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image';
// @ is an alias to /src
import chartType from '@/components/chartType.vue'
export default {
    name: 'colorPicker',
    components: {
        chartType
    },
    data () {
        return {
            title: '標題',
            chartLabels: [],
            myChart: null,
            noChart: null,

            // colorPicker 
            compareBar: null,
            currentBar: null,
            activeColor: null,
            activeHeight: null,
            activeWidth: null,

            // dragData 
            resizing: false,
            initialMouseX: 0,
            initialBarWidth: 0,

            globalChart: null,
            globalTooltip: null,
            globladata: null,

            showLegend: false,
            
            getData: 'nodata',
            activeType: 'bar',

            hName: null,
            hUnit: null,
            vName: null,
            vUnit: null,

            firstNum: null,
            lastNum: null,
            groupDist: null,

            showYLabels: true, 
            showTopLabels: true,

            clickedIndex: null,

            showHorizontal: false,
            horizontalVertical: '',

            numberOfDataPoints: 10,
            minValue: 0,
            maxValue: 100,

            randomData: null,
            totalCols: 0,
            maxNum: 0,
            groupData: [],
            bgcolor: [],
            dataResult: [],
            defaultRandom: false,
            randomNum: 1,
            childNum: 1,
            showpicker: false,
            activeColorPanel: null,
            labelCondition: true, // show x-axis labels
            inputPanel: false
        }
    },
    // props: {
    //     msg: String
    // },
    computed: {
        receivedData() {
            return this.$store.state.inputData;
        },
        receivedType() {
            return this.$store.state.activeChart;
        }
    },
    mounted() {
        // let temp = sessionStorage.getItem('activeType');
        // if(temp) {
        //     this.activeType = temp
        // } else {
        //     if(this.receivedType) {
        //         this.activeType = this.receivedType
        //         console.log(this.activeType)
        //     }
        // }

        let tempdata = sessionStorage.getItem('inputData')
        if(tempdata) {
            this.getData = JSON.parse(tempdata);
            this.commonFun();
        } else if(this.receivedData) {
            // console.log(JSON.stringify(this.getData)); // 'Hello from Page input-panel'
            this.getData = this.receivedData;
            this.commonFun();
        } else {
            this.drawCanvas1();
        }

        // color picker
        const colorPicker = this.$refs.colorPicker;

        // Using an arrow function to preserve the component's context
        colorPicker.addEventListener('input', () => {
            // Update the color of the clicked bar
            // console.log(event)
            this.myChart.data.datasets[0].backgroundColor[this.clickedIndex] = colorPicker.value;
            
            // Update the chart
            this.myChart.update();

            // Hide the color picker
            colorPicker.style.display = 'none';
        });
        
    },
    methods: {
        async printThis() {
            console.log("printing..");
            const el = this.$refs.printcontent;

            const options = {
                type: "dataURL",
            };
            const printCanvas = await html2canvas(el, options);

            const link = document.createElement("a");
            link.setAttribute("download", "download.png");
            link.setAttribute(
                "href",
                printCanvas
                .toDataURL("image/png")
                .replace("image/png", "image/octet-stream")
            );
            link.click();

            console.log("done");
        },
        captureElementAsImage() {
            const element = this.$refs.captureElement;
            domtoimage.toPng(element).then(dataUrl => {
                const link = document.createElement('a');
                link.download = 'bar.png';
                link.href = dataUrl;
                link.click();
            })
            .catch(error => {
                console.error('Error capturing element:', error);
            });
        },
        generateRandom() {
            // this.updateGenerateRandom();
            // this.updateRandom();
            this.update1Random();
        },
        generatRandom(length, min, max) {
            const randomData = [];
            for (let i = 0; i < length; i++) {
                const randomNumber = Math.random() * (max - min) + min;
                randomData.push(randomNumber);
            }
            return randomData;
        },
        updateGenerateRandom() {
            const randomData = this.generatRandom(this.numberOfDataPoints, this.minValue, this.maxValue); // Generate 10 random values
            console.log(randomData, typeof(randomData))
            const labels = randomData.map((_, index) => `類別${index + 1}`);
            this.chartLabels = labels;

            // let result = 100/parseInt(this.totalCols)
            let result = 100/10;
            result = Math.round(result)

            this.myChart.config.data.labels = labels;
            this.myChart.config.data.datasets[0].data = randomData
            this.myChart.config.options.scales.x.min = 0
            this.myChart.config.options.scales.x.max = 100
            this.myChart.config.options.scales.x.ticks.stepSize = result
            this.myChart.config.options.scales.y.min = 0
            this.myChart.config.options.scales.y.max = 100
            this.myChart.config.options.scales.y.ticks.stepSize = result

            this.myChart.update();
        },

        generateRandomData(count) {
            const data = [];
            for (let i = 0; i < count; i++) {
                data.push(Math.floor(Math.random() * 100)); // Adjust the range of random values as needed
            }
            return data;
        },
        updateRandom() {
            const randomData = this.generateRandomData(this.numberOfDataPoints); // Generate 10 random values
            // console.log(randomData, typeof(randomData))
            const labels = randomData.map((_, index) => `類別${index + 1}`);
            this.chartLabels = labels;

            // let result = 100/parseInt(this.totalCols)
            let result = 100/10;
            result = Math.round(result)

            this.myChart.config.data.labels = labels;
            this.myChart.config.data.datasets[0].data = randomData
            this.myChart.config.options.scales.x.min = 0
            this.myChart.config.options.scales.x.max = 100
            this.myChart.config.options.scales.x.ticks.stepSize = result
            this.myChart.config.options.scales.y.min = 0
            this.myChart.config.options.scales.y.max = 100
            this.myChart.config.options.scales.y.ticks.stepSize = result

            this.myChart.update();
        },
        toggleChart() {
            if(this.myChart) {
                this.showHorizontal = !this.showHorizontal;

                this.myChart.config.options.scales.x.stacked = this.showHorizontal;
                this.myChart.config.options.scales.y.stacked = this.showHorizontal;
                this.myChart.config.options.indexAxis = this.showHorizontal ? 'y' : 'x';
                console.log('last number ', this.lastNum)
                console.log('max number ', this.maxNum)
                if(this.showHorizontal) {
                    
                    // Remove the barPosition plugin from the plugins array
                    const index = this.myChart.config.plugins.findIndex(plugin => plugin.id === 'barPosition');
                    if (index !== -1) {
                        this.myChart.config.plugins.splice(index, 1);
                    }

                    this.myChart.config.options.scales = {
                        x: {
                            min: 0,
                            max: this.inputPanel === true ? this.lastNum : Math.round(this.maxNum), 
                            stacked: true,
                            ticks: {
                                color: '#262626',
                                font: {
                                    size: 18, 
                                    weight: '400', 
                                },
                                borderColor: 'red',
                                stepSize: this.groupDist !== undefined ? this.groupDist : 10,
                                callback: (value) => this.formatYAxisLabel(value)
                            }
                        },
                        y: {
                            ticks: {
                                display: this.labelCondition, 
                                color: '#000', 
                                font: {
                                    size: 20, 
                                    weight: '400', 
                                },
                                borderColor: 'red', 
                                borderWidth: 100, 
                                padding: 10, 
                            },
                            stacked: true,
                        },
                    };
                } else {

                    // Add the barPosition plugin to the plugins array
                    // https://www.youtube.com/watch?v=rW_-WXuhxp8
                    // barPosition plugin block
                    const barPosition = {
                        id: 'barPosition',
                        beforeDatasetsDraw: ( (chart, args, pluginOptions) => {
                            const { ctx, data, chartArea: {top, bottom, left, right, width, height}, scales: { x, y } } = chart;
                            console.log(args, pluginOptions, ctx, bottom, top, right, height, x, y)

                            const barHeight = width / data.labels.length;

                            // Clear the area where the default labels are drawn
                            ctx.clearRect(left, bottom + 20, right - left, height);
                            
                            chart.getDatasetMeta(0).data.forEach((dataPoint, index) => {
                                dataPoint.x = left + (barHeight * (index + 1)); // bar element setup

                                // labels setup
                                ///////
                                const xPos = dataPoint.x;
                                const yPos = y.bottom + 30; // Adjust the vertical position as needed

                                // Draw the label at the calculated position
                                ctx.save();
                                ctx.font = '20px Arial';
                                ctx.textAlign = 'center';
                                ctx.textBaseline = 'middle';
                                ctx.fillStyle = '#000'; // Set the color of the label
                                ctx.fillText(chart.data.labels[index], xPos, yPos);
                                ctx.restore();
                                ///////
                            })
                        })
                    };
                    this.myChart.config.plugins.push(barPosition);
                    
                    this.myChart.config.options.scales = {
                        x: {
                            ticks: {
                                display: this.labelCondition, 
                                color: '#000', 
                                font: {
                                    size: 20, 
                                    weight: '400', 
                                },
                                borderColor: 'red', 
                                borderWidth: 100,
                                padding: 10, 
                            }
                        },
                        y: {
                            min: 0,
                            max: this.inputPanel === true ? this.lastNum : Math.round(this.maxNum), 
                            stacked: true,
                            ticks: {
                                color: '#262626',
                                font: {
                                    size: 18, 
                                    weight: '400', 
                                },
                                borderColor: 'red',
                                stepSize: this.groupDist !== undefined ? this.groupDist : 10,
                                callback: (value) => this.formatYAxisLabel(value)
                            },
                        },
                    };
                }

                this.myChart.update();
            } else if(this.noChart) {
                this.showHorizontal = !this.showHorizontal;

                this.noChart.config.options.scales.x.stacked = this.showHorizontal;
                this.noChart.config.options.scales.y.stacked = this.showHorizontal;
                this.noChart.config.options.indexAxis = this.showHorizontal ? 'y' : 'x';

                if(this.showHorizontal) {
                    this.noChart.config.options.scales = {
                        x: {
                            min: 0,
                            max: 100,
                            stacked: true,
                            ticks: {
                                color: '#262626',
                                font: {
                                    size: 18, 
                                    weight: '400', 
                                },
                                borderColor: 'red',
                                stepSize: 10,
                                callback: (value) => this.formatYAxisLabel(value)
                            }
                        },
                        y: {
                            ticks: {
                                display: this.labelCondition, 
                                color: '#000', 
                                font: {
                                    size: 20, 
                                    weight: '400', 
                                },
                                borderColor: 'red', 
                                borderWidth: 100, 
                                padding: 10, 
                            },
                            stacked: true,
                        },
                    };
                } else {
                    this.noChart.config.options.scales = {
                        x: {
                            ticks: {
                                display: this.labelCondition, 
                                color: '#000', 
                                font: {
                                    size: 20, 
                                    weight: '400', 
                                },
                                borderColor: 'red', 
                                borderWidth: 100,
                                padding: 10, 
                            }
                        },
                        y: {
                            min: 0,
                            max: 100,
                            stacked: true,
                            ticks: {
                                color: '#262626',
                                font: {
                                    size: 18, 
                                    weight: '400', 
                                },
                                borderColor: 'red',
                                stepSize: 10,
                                callback: (value) => this.formatYAxisLabel(value)
                            },
                        },
                    };
                }

                this.noChart.update();
            }
        },
        resetAll() {
            // this.$store.commit('setInputData', tempData);
            // sessionStorage.setItem('inputData', JSON.stringify(tempData))
            // this.$store.commit('setActiveBar', this.activeType);
            // sessionStorage.setItem('activeType', this.activeType)

            // let tempdata = sessionStorage.getItem('inputData')
            // if(tempdata) {
            //     this.getData = JSON.parse(tempdata);
            // let temp = sessionStorage.getItem('activeType');
            // if(temp) {
            //     this.activeType = temp
            // }
            // }

            // Clear the entire session storage
            sessionStorage.clear();
            this.$store.commit('setInputData', null);
            this.$store.commit('setActiveBar', null);
            this.activeType = '';
            this.chartLabels = [];
            this.title = '標題'

            const labels = ['類別1', '類別2', '類別3', '類別4', '類別5'];
            this.dataResult = []
            this.hName = '名稱'
            this.hUnit = '單位'
            this.vName = '名稱'
            this.vUnit = '單位'
            this.minValue = 0
            this.maxValue = 50

            this.defaultRandom = false
            this.showYLabels = true
            this.showTopLabels = true
            this.groupData = []
            this.bgcolor = []
            this.showHorizontal = false
            this.horizontalVertical = ''

            this.myChart.config.data.labels = labels;
            this.myChart.config.data.datasets[0].data = this.dataResult
            this.myChart.config.options.scales.x.min = this.minValue
            this.myChart.config.options.scales.x.max = this.maxValue
            this.myChart.config.options.scales.x.ticks.stepSize = 10
            this.myChart.config.options.scales.y.min = this.minValue
            this.myChart.config.options.scales.y.max = this.maxValue
            this.myChart.config.options.scales.y.ticks.stepSize = 10
            this.myChart.update();
        },
        updateChart() {
          // Update chart label and data
          this.myChart.data.labels = this.chartLabels;
          this.myChart.update();
        },
        toggleLabels() {
            if(this.myChart) {
                this.showTopLabels = !this.showTopLabels;

                // Update the chart to reflect the changes
                this.myChart.config.data.datasets[0].datalabels.display = this.showTopLabels;
                this.myChart.update();
            } else if(this.noChart) {
                this.showTopLabels = !this.showTopLabels;
    
                this.noChart.config.data.datasets[0].datalabels.display = this.showTopLabels;
                this.noChart.update();
            }
        },
        // y-axis labels show/hide
        showhide() {
            this.showYLabels = !this.showYLabels;
            // this.myChart.config.options.scales.y.display = this.showYLabels;

            const chart = this.myChart;
            const yAxes = chart.options.scales.y; // Toggle the display property of y-axis labels
            yAxes.display = this.showYLabels;

            // Update the chart
            this.myChart.update();
        },
        commonFun() {
            if(this.getData.dataToSend.inputPanel) {
                this.inputPanel = true
            } else {
                this.inputPanel = false
            }
            this.hName = this.getData.dataToSend.hname
            this.hUnit = this.getData.dataToSend.hunit
            this.vName = this.getData.dataToSend.vname
            this.vUnit = this.getData.dataToSend.vunit
            this.title = this.getData.dataToSend.title
            this.totalCols = this.getData.dataToSend.totCols

            this.firstNum = parseInt(this.getData.dataToSend.setNumFirst)
            this.firstNum = isNaN(this.firstNum) ? undefined : this.firstNum
            this.lastNum = parseInt(Math.round(this.getData.dataToSend.setNumLast))
            this.lastNum = isNaN(this.lastNum) ? undefined : this.lastNum
            this.groupDist = parseInt(this.getData.dataToSend.groupDist)
            this.groupDist = isNaN(this.groupDist) ? undefined : this.groupDist

            let minYAxisValue = this.firstNum + 0.40;
            minYAxisValue = isNaN(minYAxisValue) ? undefined : minYAxisValue
            
            // setup block 
            // const labels = ['類別1', '類別2', '類別3', '類別4', '類別5'];
            this.chartLabels = this.getData.dataToSend.labels;
            if(this.chartLabels[0] === '') {
                this.chartLabels.shift()
            }
            if(this.chartLabels[this.chartLabels.length -1] !== '') {
                this.chartLabels.push('')
            }
            const dataNo = this.getData.dataToSend.dataNo;
            if(dataNo[0] === null) {
                this.getData.dataToSend.dataNo.shift();
            }

            this.bgcolor = this.getData.dataToSend.dataColor;
            let lencount = this.getData.dataToSend.dataNo;
            let tempData = {
                dataToSend: { 
                    title: this.title,
                    hname: this.hName, 
                    hunit: this.hUnit,
                    vname: this.vName,
                    vunit: this.vUnit,
                    setNumFirst: this.firstNum,
                    setNumLast: this.lastNum,
                    groupDist: this.groupDist,
                    labels: this.chartLabels,
                    dataNo: lencount,
                    dataColor: this.bgcolor,
                    totCols: lencount.length
                }
            }
            this.$store.commit('setInputData', tempData);
            sessionStorage.setItem('inputData', JSON.stringify(tempData))

            let tempArr = []
            for(let i = 0; i < parseInt(this.totalCols); i++){
                if(dataNo[i] === null) {
                    tempArr[i] = this.firstNum + 0.40;
                } else if(dataNo[i] === 0) {
                    tempArr[i] = dataNo[i] + 0.40;
                } else {
                    tempArr[i] = dataNo[i]
                }
            }
            // Filter and parse numeric values
            var numericValues = tempArr.filter(value => !isNaN(parseFloat(value)));
            // If you want to get the maximum value from the numeric values
            this.maxNum = Math.max(...numericValues);
            let remainder = this.maxNum % 10;
            if (remainder !== 0) {
                this.maxNum += (10 - remainder);
            }
            
            const dataColor = this.getData.dataToSend.dataColor;
            this.bgcolor = this.getData.dataToSend.dataColor;
            // [10, 7, 3, 5, 2]
            const data = {
                labels: this.chartLabels,
                datasets: [{
                    label: 'Dataset 1',
                    data: tempArr,
                    backgroundColor: dataColor,
                    borderWidth: 1,
                    datalabels: {
                        display: this.showTopLabels, 
                        align: 'end',
                        anchor: 'end',
                        color: "#000",
                        font: {
                            size: '24px',
                            weight: 'bold'
                        },
                        clamp: true,
                        formatter: function(value, context) { // to display labels(bar's value) at the top of each bar
                            let temp = parseFloat(value).toFixed(2).replace(/\.?0+$/, ''); // Display with two decimal places and then omit the trailing zero in the second decimal place
                            // temp = (context.dataset.data[context.dataIndex] > minYAxisValue) ? temp : ''; // condition to display the number when the data is greater than the minimum number
                            temp = ((context.dataset.data[context.dataIndex] > minYAxisValue) || ((context.dataset.data[context.dataIndex] > 0) && (minYAxisValue == undefined))) ? temp : ''; // condition to display the number when the data is greater than the minimum number
                            // if(temp !== '') {
                                temp = Math.round(temp)
                                // if(temp == 0) {
                                //     temp = ''
                                // }
                            // }
                            return temp; 
                        },
                    },
                // },
                // {
                //     label: 'Dataset 2',
                //     data: dataNo,
                //     backgroundColor: dataColor,
                //     borderWidth: 1,
                }]
            };
            this.globladata = data

            // const getOrCreateTooltip = (chart) => {
            //     let tooltipEl = chart.canvas.parentNode.querySelector('div');

            //     if (!tooltipEl) {
            //         tooltipEl = document.createElement('div');
            //         // tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
            //         tooltipEl.style.borderRadius = '3px';
            //         tooltipEl.style.color = 'white';
            //         tooltipEl.style.opacity = 1;
            //         tooltipEl.style.pointerEvents = 'none';
            //         tooltipEl.style.position = 'absolute';
            //         tooltipEl.style.transform = 'translate(-50%, 0)';
            //         tooltipEl.style.transition = 'all .1s ease';

            //         const table = document.createElement('table');
            //         table.style.margin = '0px';

            //         tooltipEl.appendChild(table);
            //         chart.canvas.parentNode.appendChild(tooltipEl);
            //     }

            //     return tooltipEl;
            // };

            // const externalTooltipHandler = (context) => {
            //     // Tooltip Element
            //     const {chart, tooltip} = context;
            //     this.globalChart = context.chart;
            //     let tooltipEl = getOrCreateTooltip(chart);

            //     // Hide if no tooltip
            //     // if (tooltip.opacity === 0) {
            //     //     tooltipEl.style.opacity = 0;
            //     //     return;
            //     // }

            //     // Set Text
            //     if (tooltip.body) {
                    
            //         // const titleLines = tooltip.title || [];
            //         const bodyLines = tooltip.body.map(b => b.lines);
            //         // const tableHead = document.createElement('thead');

            //         // titleLines.forEach(title => {
            //         //     const tr = document.createElement('tr');
            //         //     tr.style.borderWidth = 0;

            //         //     const th = document.createElement('th');
            //         //     th.style.borderWidth = 0;
            //         //     const text = document.createTextNode(title);

            //         //     th.appendChild(text);
            //         //     tr.appendChild(th);
            //         //     tableHead.appendChild(tr);
            //         // });

            //         const tableBody = document.createElement('tbody');
            //         bodyLines.forEach((body, i) => {
            //             const colors = tooltip.labelColors[i];

            //             const span = document.createElement('span');
            //             span.style.background = colors.backgroundColor;
            //             span.style.borderColor = colors.borderColor;
            //             span.style.borderWidth = '2px';
            //             span.style.marginRight = '10px';
            //             span.style.height = '10px';
            //             span.style.width = '10px';
            //             span.style.display = 'inline-block';

            //             const inputElement = document.createElement('input');
            //             console.log('hello December');
            //             inputElement.type = 'color';
            //             inputElement.id = 'colorPicker';
            //             inputElement.value = this.activeColor;
            //             inputElement.style.display = 'none';

            //             const tr = document.createElement('tr');
            //             tr.style.backgroundColor = 'inherit';
            //             tr.style.borderWidth = 0;

            //             const td = document.createElement('td');
            //             td.style.borderWidth = 0;

            //             // const text = document.createTextNode(body);

            //             td.appendChild(inputElement);
            //             // td.appendChild(text);
            //             tr.appendChild(td);
            //             tableBody.appendChild(tr);
            //         });
            //         const tableRoot = tooltipEl.querySelector('table');


            //         // Remove old children
            //         // while (tableRoot.firstChild) {
            //         //     console.log(tableRoot)
            //         //     tableRoot.firstChild.remove();
            //         //     console.log(tableRoot)
            //         // }

            //         // Add new children
            //         // tableRoot.appendChild(tableHead);
            //         tableRoot.appendChild(tableBody);

            //         // Assuming colorPickerInput is the input element in your tooltip
            //         const colorPickerInput = document.getElementById('colorPicker'); // Replace with the actual ID or selector

            //         colorPickerInput.addEventListener('click', () => {
            //             // Your logic when the color picker is clicked
            //             console.log('Color picker clicked');
            //         });

            //         colorPickerInput.click();
                    

            //         // Using an arrow function to preserve the component's context
            //         colorPickerInput.addEventListener('input', () => {
            //             // Update the color of the clicked bar
            //             // console.log(event)
            //             chart.data.datasets[0].backgroundColor[this.currentBar] = colorPickerInput.value;
                        
            //             // Update the chart
            //             chart.update();

            //             tooltipEl = chart.canvas.parentNode.querySelector('div');
            //         });
            //     }

            //     const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;

            //     // Display, position, and set styles for font
            //     tooltipEl.style.opacity = 1;
            //     tooltipEl.style.left = positionX + tooltip.caretX + 'px';
            //     tooltipEl.style.top = positionY + tooltip.caretY + 'px';
            //     tooltipEl.style.font = tooltip.options.bodyFont.string;
            //     tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';

            //     this.globalTooltip = tooltipEl;
                
            // };

            // https://www.youtube.com/watch?v=rW_-WXuhxp8
            // barPosition plugin block
            const barPosition = {
                id: 'barPosition',
                beforeDatasetsDraw: ( (chart, args, pluginOptions) => {
                    const { ctx, data, chartArea: {top, bottom, left, right, width, height}, scales: { x, y } } = chart;
                    console.log(args, pluginOptions, ctx, bottom, top, right, height, x, y)

                    const barHeight = width / data.labels.length;

                    // Clear the area where the default labels are drawn
                    ctx.clearRect(left, bottom + 20, right - left, height);
                    
                    chart.getDatasetMeta(0).data.forEach((dataPoint, index) => {
                        dataPoint.x = left + (barHeight * (index + 1)); // bar element setup

                        // labels setup
                        ///////
                        const xPos = dataPoint.x;
                        const yPos = y.bottom + 30; // Adjust the vertical position as needed

                        // Draw the label at the calculated position
                        ctx.save();
                        ctx.font = '20px Arial';
                        ctx.textAlign = 'center';
                        ctx.textBaseline = 'middle';
                        ctx.fillStyle = '#000'; // Set the color of the label
                        ctx.fillText(chart.data.labels[index], xPos, yPos);
                        ctx.restore();
                        ///////
                    })
                })
            };

            // config block 
            let verticalOpt = {
                indexAxis: 'x',
                scales: {
                    x: {
                        // grid: {
                        //     display: true, // Display x-axis grid lines
                        //     color: 'rgba(0, 0, 0, 0.1)', // Set grid line color
                        //     lineWidth: 1, // Set grid line width
                        //     drawTicks: true, // Hide ticks
                        //     drawOnChartArea: true, // Do not draw grid lines on the chart area
                        // },
                        ticks: {
                            display: this.labelCondition, 
                            color: '#000', // Set the color of the x-axis labels
                            font: {
                                size: 20, // Adjust the font size as needed
                                weight: '400', // Adjust the font weight as needed
                            },
                            borderColor: 'red', // Set the border color
                            borderWidth: 100, // Set the border width
                            padding: 10, // Set the padding
                        },
                        // stacked: true,
                        // barPercentage: 0.8, // Adjust the width of each bar
                        // categoryPercentage: 0.6, // Adjust the width of the entire category (vertical spacing)
                    },
                    // y: {
                    //     stacked: true
                    // }
                    // y: {
                    //     min: this.firstNum,
                    //     stepSize: 19, // Set the step size to 1 for whole numbers
                    //     max: this.lastNum, 
                    //     position: 'left',
                    // },
                    y: {
                        min: this.firstNum !== undefined ? this.firstNum : 0,
                        max: ((this.lastNum !== undefined) && (this.inputPanel == true)) ? this.lastNum : Math.round(this.maxNum), // input panel last value need to show at chart
                        stacked: true,
                        ticks: {
                            color: '#262626',
                            font: {
                                size: 18, 
                                weight: '400', 
                            },
                            borderColor: 'red',
                            stepSize: this.groupDist !== undefined ? this.groupDist : 10,
                            callback: (value) => this.formatYAxisLabel(value),
                            
                            // callback: (value) => {
                            //     console.log('hello 1 =>')
                            //     // console.log('hello 1 =>', value, index, values)
                            //     return this.showYLabels ? value : '$sign';
                            // },
                        },
                    },
                },
                plugins: {
                    legend: {
                        display: this.showLegend
                    },
                    tooltip: {
                        enabled: false,
                        // position: 'nearest',
                        // external: externalTooltipHandler
                    },
                    dragData: {
                        // showTooltip: false,
                        // round: 1,
                        // magnet: {
                        //     // to: Math.round
                        //     to: (value) => value + 2
                        // },
                        onDragStart: (event, element) => {
                            // console.log('Drag Start:', event, element);
                            this.resizing = true;
                            this.initialMouseX = event.x;
                            this.initialBarWidth = element.width;
                        },
                        onDrag: (event, element, context, value) => {
                            event.target.style.cursor = 'grabbing'; 
                            let temp = this.firstNum + 0.40;

                            // how can I update max value of Y-axis when dragging bar datalabels
                            let tempdata = sessionStorage.getItem('inputData')
                            this.getData = JSON.parse(tempdata);
                            this.getData.dataToSend.dataNo[context] = value

                            this.maxNum = Math.max(...this.getData.dataToSend.dataNo);
                            let maxval = Math.max(...this.getData.dataToSend.dataNo) + 50;

                            // Adjust the max value to be divisible by 10
                            let remainder = this.maxNum % 10;
                            if (remainder !== 0) {
                                this.maxNum += (10 - remainder);
                            }
                            let remainder1 = maxval % 10;
                            if (remainder1 !== 0) {
                                maxval += (10 - remainder1);
                            }
                            this.getData.dataToSend.setNumLast = this.maxNum
                            sessionStorage.setItem('inputData', JSON.stringify(this.getData))
                            
                            let currentStepsize = this.myChart.scales.y
                            // Calculate the visible range of the y-axis
                            let visibleRange = Math.abs(currentStepsize.max - currentStepsize.min);
                            // Get the number of ticks displayed on the y-axis
                            let numTicks = currentStepsize.ticks.length;
                            // Calculate the step size
                            let stepSize = visibleRange / (numTicks - 1);
                            console.log('Y-axis Step Size:', stepSize);

                            this.lastNum = Math.round(this.maxNum)
                            // how can I update max value of Y-axis when dragging bar datalabels
                            this.myChart.options.scales.y.max = maxval;
                            this.myChart.update();

                            if(value <= temp) {
                                return false 
                            }
                        },
                        onDragEnd: (event) => {
                            // console.log('Drag End:', event);
                            event.target.style.cursor = 'default';
                            this.resizing = false;
                        }
                    }
                },
                // events: ['click'], // Enable click events
                // onClick: this.colorChartClick,
                onClick: this.colorInputClick,

                onHover: this.handleHover,
            }

            if(this.showHorizontal == false) {
                this.horizontalVertical = verticalOpt;
            } 

            // https://stackoverflow.com/questions/72214227/chart-js-add-direction-arrows-to-the-x-and-y-axes
            const customBorder = {
                id: 'customBorder',
                afterDatasetsDraw(chart) {
                    const {
                        ctx,
                        chartArea: {
                            top,
                            bottom,
                            left,
                            right
                        }
                    } = chart;
                    // console.log(args)
                    // console.log(pluginOptions)


                    ctx.save();
                    ctx.beginPath();
                    ctx.lineWidth = 2;
                    ctx.strokeStyle = "gray";

                    ctx.moveTo(left - 1, top + 3);
                    ctx.lineTo(left + 5, top + 10);
                    ctx.moveTo(left + 1, top + 3);
                    ctx.lineTo(left - 5, top + 10);
                    ctx.moveTo(left, top + 5);
                    ctx.lineTo(left, bottom);
                    ctx.lineTo(right - 5, bottom);
                    ctx.moveTo(right - 3, bottom + 1)
                    ctx.lineTo(right - 10, bottom - 5);
                    ctx.moveTo(right - 3, bottom - 1);
                    ctx.lineTo(right - 10, bottom + 5);
                    ctx.stroke();
                    ctx.closePath();
                }
            }

            const config = {
                type: 'bar',
                data: data,
                plugins: [ChartDataLabels, customBorder, barPosition], 
                options: this.horizontalVertical
            }

            var ctx = document.getElementById('myChart');

            // Create the bar chart
            // this.myChart = new Chart(ctx, config);
            // https://stackoverflow.com/questions/68602389/maximum-call-stack-error-when-attempting-to-update-chart-in-vue-js
            this.myChart = shallowRef(
                new Chart(ctx, config)
            );

            // hover event
            this.myChart.canvas.addEventListener('mousemove', (e) => {
                this.mousemoveHandler(e, this.myChart)
            })


            // function rangeWithSkips(startSkip, endSkip) {
            //     return {
            //         data: dataNo.filter((v, k) => { 
            //             return (k < startSkip || k > endSkip) // Get all except between the desired range
            //         }),
            //     };
            // }
            
            // const startSkip = document.getElementById('startSkip')
            // const endSkip = document.getElementById('endSkip')

            // function updateChart(s, e) {
            //     this.myChart.data.datasets[0].data = rangeWithSkips(s, e).data
            //     console.log("s, e", s, e, this.myChart.data)
            //     this.myChart.update();
            // }

            // startSkip.addEventListener('input', () => {
            //     updateChart(startSkip.val(), endSkip.val())
            // });
            // endSkip.addEventListener('input', () => {
            //     updateChart(startSkip.val(), endSkip.val())
            // });

        },

        selectColor(color) {
            this.myChart.data.datasets[0].backgroundColor[this.activeColorPanel] = color;
            this.bgcolor[this.activeColorPanel] = color
            this.showpicker = false;
            this.myChart.update();
        },
        colorInputClick(event, chartElement) {
            if (chartElement.length > 0) {
                // Get the index of the clicked bar
                this.showpicker = true
                this.activeColorPanel = chartElement[0].index;
            }
        },

        colorChartClick(event, chartElement) {
            if (chartElement.length > 0) {
                // Get the index of the clicked bar
                this.clickedIndex = chartElement[0].index;

                // Show the color picker
                const colorPicker = this.$refs.colorPicker;
                colorPicker.style.display = 'block';

                // Set the color picker value to the color of the clicked bar
                colorPicker.value = this.myChart.data.datasets[0].backgroundColor[this.clickedIndex];
                colorPicker.click();
            }
        },
        formatYAxisLabel(value) {
            return this.showYLabels ? Math.floor(value) : `$${value}`;
        },
        routetoLine() {
            let tempinput = sessionStorage.getItem('inputData')
            if(tempinput) {
                console.log('ifififif ', this.bgcolor)
                this.getData = JSON.parse(tempinput);
                let lencount = this.getData.dataToSend.dataNo;
                let tempData = {
                    dataToSend: { 
                        title: this.title,
                        hname: this.hName, 
                        hunit: this.hUnit,
                        vname: this.vName,
                        vunit: this.vUnit,
                        setNumFirst: this.firstNum,
                        setNumLast: this.lastNum,
                        groupDist: this.groupDist,
                        labels: this.chartLabels,
                        dataNo: this.getData.dataToSend.dataNo,
                        dataColor: this.bgcolor,
                        totCols: lencount.length
                    }
                }
                this.$store.commit('setInputData', tempData);
                sessionStorage.setItem('inputData', JSON.stringify(tempData))
            } else {
                let tempData = {
                    dataToSend: { 
                        title: this.title,
                        hname: this.hName, 
                        hunit: this.hUnit,
                        vname: this.vName,
                        vunit: this.vUnit,
                        setNumFirst: this.firstNum,
                        setNumLast: this.lastNum,
                        groupDist: this.groupDist,
                        labels: this.chartLabels,
                        dataNo: this.groupData,
                        dataColor: this.bgcolor,
                        totCols: this.groupData.length
                    }
                }
                this.$store.commit('setInputData', tempData);
                sessionStorage.setItem('inputData', JSON.stringify(tempData))
            }
            this.$router.push('/input-panel');
        },
        generateData() {
            this.defaultRandom = true

            if(this.randomNum == 1) {
                this.title = '3年甲班班長投票數'
                this.hName = '候選人'
                this.hUnit = 'Null'
                this.vName = '得票數'
                this.vUnit = '張數'
                this.chartLabels = ['黃大恩', '金小平', '謝宣宣', ''];
                this.bgcolor = ['#FF6161', '#70B5FF', '#22FF2A']

                this.dataResult = 100/3;
                this.dataResult = parseFloat(this.dataResult).toFixed(2).replace(/\.?0+$/, ''); 
                
                if(this.childNum == 1) {
                    this.groupData = [9, 13, 11]
                } else if(this.childNum == 2) {
                    this.groupData = [12, 15, 14]
                } else if(this.childNum == 3) {
                    this.groupData = [8, 8, 16]
                } else if(this.childNum == 4) {
                    this.groupData = [6, 24, 3]
                } else if(this.childNum == 5) {
                    this.groupData = [18, 16, 17]
                    this.randomNum = 2
                }

                if(this.childNum == 5) {
                    this.childNum = 1;
                } else {
                    this.childNum++;
                }

                sessionStorage.setItem('saveRanNum', this.randomNum)
            } else if(this.randomNum == 2) {
                this.title = '東明國小三年級血型人數'
                this.hName = '血型'
                this.hUnit = 'Null'
                this.vName = '人數'
                this.vUnit = '人'
                this.chartLabels = ['A', 'B', 'O', 'AB', ''];
                this.bgcolor = ['#FF6161', '#70B5FF', '#52FF54', '#FFF824']

                this.dataResult = 100/4;
                this.dataResult = parseFloat(this.dataResult).toFixed(2).replace(/\.?0+$/, ''); 
                
                if(this.childNum == 1) {
                    this.groupData = [18, 22, 13, 8]
                } else if(this.childNum == 2) {
                    this.groupData = [19, 23, 18, 9]
                } else if(this.childNum == 3) {
                    this.groupData = [20, 23, 20, 10]
                } else if(this.childNum == 4) {
                    this.groupData = [21, 24, 10, 15]
                } else if(this.childNum == 5) {
                    this.groupData = [22, 20, 8, 17]
                    this.randomNum = 3
                }

                if(this.childNum == 5) {
                    this.childNum = 1;
                } else {
                    this.childNum++;
                }

                sessionStorage.setItem('saveRanNum', this.randomNum)
            } else if(this.randomNum == 3) {
                this.title = '小琉球海龜目擊統計表'
                this.hName = '海龜'
                this.hUnit = '種類'
                this.vName = '目擊次數'
                this.vUnit = '次'
                this.chartLabels = ['綠蠵龜', '赤蠵龜', '欖蠵龜', '革龜', '玳瑁', ''];
                this.bgcolor = ['#11E481', '#FF6F52', '#FEAC39', '#7589F0', '#FFD438']

                this.dataResult = 100/5;
                this.dataResult = parseFloat(this.dataResult).toFixed(2).replace(/\.?0+$/, ''); 
                
                if(this.childNum == 1) {
                    this.groupData = [50, 15, 8, 3, 24]
                } else if(this.childNum == 2) {
                    this.groupData = [51, 20, 10, 0.40, 25]
                } else if(this.childNum == 3) {
                    this.groupData = [40, 22, 12, 5, 30]
                } else if(this.childNum == 4) {
                    this.groupData = [53, 17, 5, 7, 40]
                } else if(this.childNum == 5) {
                    this.groupData = [60, 11, 0.40, 2, 12]
                    this.randomNum = 4
                }

                if(this.childNum == 5) {
                    this.childNum = 1;
                } else {
                    this.childNum++;
                }

                sessionStorage.setItem('saveRanNum', this.randomNum)
            } else if(this.randomNum == 4) {
                this.title = '手搖飲料販售帳簿'
                this.hName = '飲品'
                this.hUnit = 'Null'
                this.vName = '杯數'
                this.vUnit = '杯'
                this.chartLabels = ['珍珠奶茶', '水果茶', '伯爵茶', '百香綠茶', '蜂蜜檸檬', '玫瑰拿鐵', ''];
                this.bgcolor = ['#11E40C', '#FF6F52', '#FEAC39', '#7589F0', '#FFD438', '#39D7FE']

                this.dataResult = 100/6;
                this.dataResult = parseFloat(this.dataResult).toFixed(2).replace(/\.?0+$/, ''); 
                
                if(this.childNum == 1) {
                    this.groupData = [120, 156, 68, 74, 23, 112]
                } else if(this.childNum == 2) {
                    this.groupData = [100, 120, 100, 75, 28, 120]
                } else if(this.childNum == 3) {
                    this.groupData = [110, 140, 90, 80, 36, 110]
                } else if(this.childNum == 4) {
                    this.groupData = [150, 130, 85, 100, 40, 100]
                } else if(this.childNum == 5) {
                    this.groupData = [111, 100, 70, 50, 30, 95]
                    this.randomNum = 5
                }

                if(this.childNum == 5) {
                    this.childNum = 1;
                } else {
                    this.childNum++;
                }

                sessionStorage.setItem('saveRanNum', this.randomNum)
            } else if(this.randomNum == 5) {
                this.title = '6年6班各種寵物飼養人數'
                this.hName = '寵物種類'
                this.hUnit = 'Null'
                this.vName = '飼養人數'
                this.vUnit = '人'
                this.chartLabels = ['狗狗', '貓咪', '鸚鵡', '觀賞魚', '天竺鼠', '其他', ''];
                this.bgcolor = ['#11E481', '#FF6F52', '#FEAC39', '#7589F0', '#FFD438', '#39D7FE']

                this.dataResult = 100/6;
                this.dataResult = parseFloat(this.dataResult).toFixed(2).replace(/\.?0+$/, ''); 
                
                if(this.childNum == 1) {
                    this.groupData = [15, 18, 3, 11, 10, 7]
                } else if(this.childNum == 2) {
                    this.groupData = [20, 20, 0.10, 0.10, 8, 6]
                } else if(this.childNum == 3) {
                    this.groupData = [25, 30, 2, 13, 23, 8]
                } else if(this.childNum == 4) {
                    this.groupData = [40, 22, 1, 12, 15, 10]
                } else if(this.childNum == 5) {
                    this.groupData = [50, 25, 5, 10, 17, 15]
                    this.randomNum = 6
                }

                if(this.childNum == 5) {
                    this.childNum = 1;
                } else {
                    this.childNum++;
                }

                sessionStorage.setItem('saveRanNum', this.randomNum)
            } else if(this.randomNum == 6) {
                this.title = '台灣旅客出國資料'
                this.hName = '國家'
                this.hUnit = 'Null'
                this.vName = '旅遊人次'
                this.vUnit = '萬人'
                this.chartLabels = ['日本', '韓國', '美國', '泰國', '越南', '馬來西亞', '中國', ''];
                this.bgcolor = ['#F25050', '#526EFF', '#FFF952', '#7589F0', '#FE7CF9', '#66EDFF', '#78DB76']

                this.dataResult = 100/8;
                this.dataResult = parseFloat(this.dataResult).toFixed(2).replace(/\.?0+$/, ''); 
                
                if(this.childNum == 1) {
                    this.groupData = [59, 48, 31, 37, 45, 7, 34]
                } else if(this.childNum == 2) {
                    this.groupData = [66, 50, 35, 28, 25, 8, 35]
                } else if(this.childNum == 3) {
                    this.groupData = [68, 48, 40, 27, 36, 9, 29]
                } else if(this.childNum == 4) {
                    this.groupData = [70, 75, 28, 36, 38, 10, 10]
                } else if(this.childNum == 5) {
                    this.groupData = [85, 69, 26, 40, 42, 11, 11]
                    this.randomNum = 1
                }

                if(this.childNum == 5) {
                    this.childNum = 1;
                } else {
                    this.childNum++;
                }

                sessionStorage.setItem('saveRanNum', this.randomNum)
            }

            // Filter and parse numeric values
            var numericValues = this.groupData.filter(value => !isNaN(parseFloat(value)));
            // If you want to get the maximum value from the numeric values
            this.maxNum = Math.max(...numericValues);
            let remainder = this.maxNum % 10;
            if (remainder !== 0) {
                this.maxNum += (10 - remainder);
            }

            // this.firstNum = this.firstNum && this.firstNum !== undefined ? this.firstNum : 0
            // this.lastNum = this.lastNum && this.lastNum !== undefined ? this.lastNum : Math.round(this.maxNum)

            /////////
            let tempData = {
                dataToSend: { 
                    title: this.title,
                    hname: this.hName, 
                    hunit: this.hUnit,
                    vname: this.vName,
                    vunit: this.vUnit,
                    setNumFirst: this.firstNum && this.firstNum !== undefined ? this.firstNum : 0,
                    setNumLast: this.lastNum && this.lastNum !== undefined ? this.lastNum : Math.round(this.maxNum),
                    // groupDist: this.groupDist,
                    labels: this.chartLabels,
                    dataNo: this.groupData,
                    dataColor: this.bgcolor,
                    totCols: this.groupData.length
                }
            }
            this.$store.commit('setInputData', tempData);
            sessionStorage.setItem('inputData', JSON.stringify(tempData))
            /////////
        },
        update1Random() {
            this.generateData();
            
            this.myChart.config.data.labels = this.chartLabels;
            console.log(this.chartLabels)
            this.myChart.config.data.datasets[0].data = this.groupData
            this.myChart.config.data.datasets[0].backgroundColor = this.bgcolor
            this.myChart.config.options.scales.x.ticks.stepSize = 10
            this.myChart.config.options.scales.y.ticks.stepSize = 10

            this.myChart.config.options.scales.x.min = 0
            this.myChart.config.options.scales.x.max = this.maxNum;
            this.myChart.config.options.scales.y.min = 0
            this.myChart.config.options.scales.y.max = this.maxNum;
            this.myChart.update();

            let currentStepsize = this.myChart.scales.y
            let visibleRange = Math.abs(currentStepsize.max - currentStepsize.min);
            let numTicks = currentStepsize.ticks.length;
            let stepSize = visibleRange / (numTicks - 1);
            let temp = this.maxNum + stepSize;
            this.myChart.config.options.scales.x.max = temp;
            this.myChart.config.options.scales.y.max = temp;
            this.myChart.update();
        },
        drawCanvas() {
            var ctx = document.getElementById('noChart');

            const labels = ['類別1', '類別2', '類別3', '類別4', '類別5'];
            const data = {
                labels: labels,
                datasets: [{
                    label: '# of Votes',
                    data: [],
                    backgroundColor: 'rgba(0, 123, 255, 0)', // Bar color (transparent to hide bars)
                    borderColor: 'rgba(0, 123, 255, 0)', // Border color
                    borderWidth: 1,
                    // barPercentage: 0.1, // Adjust the width of the invisible bars
                    datalabels: {
                        display: this.showTopLabels, 
                        align: 'end',
                        anchor: 'end',
                        color: "#000",
                        font: {
                            size: '24px',
                            weight: 'bold'
                        },
                        clamp: true,
                        formatter: function(value, context) { // to display labels(bar's value) at the top of each bar
                            let temp = parseFloat(value).toFixed(2).replace(/\.?0+$/, ''); // Display with two decimal places and then omit the trailing zero in the second decimal place
                            temp = Math.round(temp)
                            console.log(context)
                            return temp; 
                        },
                    },
                }]
            };

            // https://stackoverflow.com/questions/72214227/chart-js-add-direction-arrows-to-the-x-and-y-axes
            const customBorder = {
                id: 'customBorder',
                afterDatasetsDraw(chart) {
                    const {
                        ctx,
                        chartArea: {
                            top,
                            bottom,
                            left,
                            right
                        }
                    } = chart;
                    // console.log(args)
                    // console.log(pluginOptions)


                    ctx.save();
                    ctx.beginPath();
                    ctx.lineWidth = 2;
                    ctx.strokeStyle = "gray";

                    ctx.moveTo(left - 1, top + 3);
                    ctx.lineTo(left + 5, top + 10);
                    ctx.moveTo(left + 1, top + 3);
                    ctx.lineTo(left - 5, top + 10);
                    ctx.moveTo(left, top + 5);
                    ctx.lineTo(left, bottom);
                    ctx.lineTo(right - 5, bottom);
                    ctx.moveTo(right - 3, bottom + 1)
                    ctx.lineTo(right - 10, bottom - 5);
                    ctx.moveTo(right - 3, bottom - 1);
                    ctx.lineTo(right - 10, bottom + 5);
                    ctx.stroke();
                    ctx.closePath();
                }
            }

            // Calculate the maximum value in the dataset
            // const maxValue = Math.max(...data.datasets[0].data);
            const config = {
                type: 'bar',
                data: data,
                options: {
                    indexAxis: 'x',
                    responsive: true,
                    scales: {
                        y: {
                            beginAtZero: true,
                            min: 0,
                            // max: maxValue + 1, // Adjust the max value to accommodate the data
                            max: 100,
                            stacked: true,
                            ticks: {
                                color: '#000', 
                                font: {
                                    size: 20, 
                                    weight: '400', 
                                },
                                borderColor: 'red', 
                                borderWidth: 100, 
                                padding: 10, 
                                stepSize: 10,
                                callback: (value) => this.formatYAxisLabel(value),
                            },
                            position: 'left',
                        },
                        x: {
                            beginAtZero: true,
                            position: 'bottom',
                            
                            min: 0,
                            max: 100,
                            stacked: true,
                            ticks: {
                                color: '#262626',
                                font: {
                                    size: 18, 
                                    weight: '400', 
                                },
                                borderColor: 'red',
                                borderWidth: 100, // Set the border width
                                padding: 10, // Set the padding
                            },
                        }
                    },
                    plugins: {
                        legend: {
                            display: this.showLegend
                        },
                        tooltip: {
                            enabled: false,
                        },
                        dragData: {
                            // showTooltip: false,
                            // round: 1,
                            // magnet: {
                            //     // to: Math.round
                            //     to: (value) => value + 2
                            // },
                            onDragStart: (event, element) => {
                                // console.log('Drag Start:', event, element);
                                this.resizing = true;
                                this.initialMouseX = event.x;
                                this.initialBarWidth = element.width;
                            },
                            onDrag: (event, element, context, value) => {
                                event.target.style.cursor = 'grabbing'; 
                                let temp = this.firstNum + 0.40;

                                // how can I update max value of Y-axis when dragging bar datalabels
                                this.noChart.options.scales.y.max = value+10;
                                this.noChart.update();

                                if(value <= temp) {
                                    return false 
                                }
                            },
                            onDragEnd: (event) => {
                                // console.log('Drag End:', event);
                                event.target.style.cursor = 'default';
                                this.resizing = false;
                            }
                        }
                    }

                    
                },
                plugins: [ChartDataLabels, customBorder],
            }
            // Create the chart
            // new Chart(ctx, config);
            // this.noChart = new Chart(ctx, config);
            this.noChart = shallowRef(
                new Chart(ctx, config)
            );

            // hover event
            this.noChart.canvas.addEventListener('mousemove', (e) => {
                this.mousemoveHandler(e, this.noChart)
            })
        },
        drawCanvas1() {
            this.generateData();
            const data = {
                labels: this.chartLabels,
                datasets: [{
                    label: 'Dataset 1',
                    data: this.groupData,
                    backgroundColor: this.bgcolor,
                    borderWidth: 1,
                    datalabels: {
                        display: this.showTopLabels, 
                        align: 'end',
                        anchor: 'end',
                        color: "#000",
                        font: {
                            size: '24px',
                            weight: 'bold'
                        },
                        clamp: true,
                        formatter: function(value, context) { // to display labels(bar's value) at the top of each bar
                            let temp = parseFloat(value).toFixed(2).replace(/\.?0+$/, ''); // Display with two decimal places and then omit the trailing zero in the second decimal place
                            temp = Math.round(temp)
                            // if(temp == 0) {
                            //     temp = ''
                            // }
                            console.log(context)
                            return temp; 
                        },
                    },
                // },
                // {
                //     label: 'Dataset 2',
                //     data: dataNo,
                //     backgroundColor: dataColor,
                //     borderWidth: 1,
                }]
            };
            this.globladata = data

            // https://www.youtube.com/watch?v=rW_-WXuhxp8
            // barPosition plugin block
            // How to Move Starting Position of Bar Chart in Chart JS
            const barPosition = {
                id: 'barPosition',
                beforeDatasetsDraw: ( (chart, args, pluginOptions) => {
                    const { ctx, data, chartArea: {top, bottom, left, right, width, height},
                    scales: {x, y}} = chart;
                    console.log(args, pluginOptions, ctx, bottom, top, right, height, x, y)

                    const barHeight = width / data.labels.length;
                    
                    // Clear the area where the default labels are drawn
                    ctx.clearRect(left, bottom + 20, right - left, height);
                    
                    chart.getDatasetMeta(0).data.forEach((dataPoint, index) => {
                        dataPoint.x = left + (barHeight * (index + 1)); // bar element setup

                        // labels setup
                        ///////
                        const xPos = dataPoint.x;
                        const yPos = y.bottom + 30; // Adjust the vertical position as needed

                        // Draw the label at the calculated position
                        ctx.save();
                        ctx.font = '20px Arial';
                        ctx.textAlign = 'center';
                        ctx.textBaseline = 'middle';
                        ctx.fillStyle = '#000'; // Set the color of the label
                        ctx.fillText(chart.data.labels[index], xPos, yPos);
                        ctx.restore();
                        ///////
                    })
                })
            };

            // config block 
            let verticalOpt = {
                indexAxis: 'x',
                scales: {
                    y: {
                        min: this.firstNum && this.firstNum !== undefined ? this.firstNum : 0,
                        max: this.lastNum && this.lastNum !== undefined ? this.lastNum : Math.round(this.maxNum),
                        stacked: true,
                        ticks: {
                            color: '#000', 
                            font: {
                                size: 18, 
                                weight: '400', 
                            },
                            borderColor: 'red', 
                            stepSize: 10,
                            callback: (value) => this.formatYAxisLabel(value),
                        },
                    },
                    x: {
                        ticks: {
                            display: this.labelCondition, 
                            color: '#000',
                            font: {
                                size: 20, 
                                weight: '400', 
                            },
                            borderColor: 'red',
                            borderWidth: 100, // Set the border width
                            padding: 10, // Set the padding
                        },
                    }
                },
                plugins: {
                    legend: {
                        display: this.showLegend
                    },
                    tooltip: {
                        enabled: false,
                        // position: 'nearest',
                        // external: externalTooltipHandler
                    },
                    dragData: {
                        // showTooltip: false,
                        // round: 1,
                        // magnet: {
                        //     // to: Math.round
                        //     to: (value) => value + 2
                        // },
                        onDragStart: (event, element) => {
                            // console.log('Drag Start:', event, element);
                            this.resizing = true;
                            this.initialMouseX = event.x;
                            this.initialBarWidth = element.width;
                        },
                        onDrag: (event, element, context, value) => {
                            event.target.style.cursor = 'grabbing'; 
                            let temp = this.firstNum + 0.40;

                            // how can I update max value of Y-axis when dragging bar datalabels
                            let tempdata = sessionStorage.getItem('inputData')
                            this.getData = JSON.parse(tempdata);
                            this.getData.dataToSend.dataNo[context] = value

                            this.maxNum = Math.max(...this.getData.dataToSend.dataNo);
                            let maxval = Math.max(...this.getData.dataToSend.dataNo) + 50;

                            // Adjust the max value to be divisible by 10
                            let remainder = this.maxNum % 10;
                            if (remainder !== 0) {
                                this.maxNum += (10 - remainder);
                            }
                            let remainder1 = maxval % 10;
                            if (remainder1 !== 0) {
                                maxval += (10 - remainder1);
                            }
                            this.getData.dataToSend.setNumLast = this.maxNum
                            sessionStorage.setItem('inputData', JSON.stringify(this.getData))
                            
                            let currentStepsize = this.myChart.scales.y
                            // Calculate the visible range of the y-axis
                            let visibleRange = Math.abs(currentStepsize.max - currentStepsize.min);
                            // Get the number of ticks displayed on the y-axis
                            let numTicks = currentStepsize.ticks.length;
                            // Calculate the step size
                            let stepSize = visibleRange / (numTicks - 1);
                            console.log('Y-axis Step Size:', stepSize);

                            this.lastNum = Math.round(this.maxNum)
                            this.myChart.options.scales.y.max = Math.round(maxval);
                            this.myChart.update();

                            if(value <= temp) {
                                return false 
                            }
                        },
                        onDragEnd: (event) => {
                            // console.log('Drag End:', event);
                            event.target.style.cursor = 'default';
                            this.resizing = false;
                        }
                    }
                },
                // events: ['click'], // Enable click events
                // onClick: this.colorChartClick,
                onClick: this.colorInputClick,

                onHover: this.handleHover,
            }

            if(this.showHorizontal == false) {
                this.horizontalVertical = verticalOpt;
            } 

            // https://stackoverflow.com/questions/72214227/chart-js-add-direction-arrows-to-the-x-and-y-axes
            const customBorder = {
                id: 'customBorder',
                afterDatasetsDraw(chart) {
                    const {
                        ctx,
                        chartArea: {
                            top,
                            bottom,
                            left,
                            right
                        }
                    } = chart;
                    // console.log(args)
                    // console.log(pluginOptions)


                    ctx.save();
                    ctx.beginPath();
                    ctx.lineWidth = 2;
                    ctx.strokeStyle = "gray";

                    ctx.moveTo(left - 1, top + 3);
                    ctx.lineTo(left + 5, top + 10);
                    ctx.moveTo(left + 1, top + 3);
                    ctx.lineTo(left - 5, top + 10);
                    ctx.moveTo(left, top + 5);
                    ctx.lineTo(left, bottom);
                    ctx.lineTo(right - 5, bottom);
                    ctx.moveTo(right - 3, bottom + 1)
                    ctx.lineTo(right - 10, bottom - 5);
                    ctx.moveTo(right - 3, bottom - 1);
                    ctx.lineTo(right - 10, bottom + 5);
                    ctx.stroke();
                    ctx.closePath();
                }
            }

            const config = {
                type: 'bar',
                data: data,
                plugins: [ChartDataLabels, customBorder, barPosition], 
                options: this.horizontalVertical
            }

            var ctx = document.getElementById('myChart');

            // Create the bar chart
            // this.myChart = new Chart(ctx, config);
            // https://stackoverflow.com/questions/68602389/maximum-call-stack-error-when-attempting-to-update-chart-in-vue-js
            this.myChart = shallowRef(
                new Chart(ctx, config)
            );

            // hover event
            this.myChart.canvas.addEventListener('mousemove', (e) => {
                this.mousemoveHandler(e, this.myChart)
            })
        },
        // handleChartClick() {
        //     console.log('good result');
        //     // Assuming colorPickerInput is the input element in your tooltip
        //     const colorPickerInput = document.getElementById('colorPicker'); // Replace with the actual ID or selector

        //     colorPickerInput.addEventListener('click', () => {
        //         // Your logic when the color picker is clicked
        //         console.log('Color picker clicked');
        //     });

        //     colorPickerInput.click();
            

        //     // Using an arrow function to preserve the component's context
        //     colorPickerInput.addEventListener('input', () => {
        //         // Update the color of the clicked bar
        //         // this.myChart.data.datasets[0].backgroundColor[this.currentBar] = colorPickerInput.value;
                
        //         // // Update the chart
        //         // this.myChart.update();

        //         // this.globalTooltip = this.myChart.canvas.parentNode.querySelector('div');
        //         this.handleColorSelection(colorPickerInput.value);
        //     });
        // },
        // handleColorSelection(selectedColor) {
        //     // Update the chart data based on the selected color
        //     // console.log(this.myChart.canvas)
        //     this.globladata.datasets[0].backgroundColor = selectedColor;
        //     // this.myChart.update();
        //     // Redraw or update the chart (you may need to call the appropriate method based on your chart library)
        //     this.updateChart();
        // },
        // updateChart() {
        //     const chart = this.$refs.myRefChart;

        //     // Check if the chart instance exists
        //     if (chart) {
        //         // Update the chart data
        //         console.log(this.globladata)
        //         this.globladata.data = this.globladata;

        //         // Redraw the chart
        //         chart.update();
        //     }
        // },
        handleHover(event, elements) {
            // Check if there are hovered elements
            if (elements.length > 0) {
                this.currentBar = elements[0].index
                this.activeColor = elements[0].element.options.backgroundColor;
                // console.log('click event ' + this.currentBar)
            } 
        },
        mousemoveHandler(mousemove, chart) {
            const cursorPosition = chart.getElementsAtEventForMode(mousemove, 'nearest', { intersect: true}, true);
            if(cursorPosition[0]) {
                console.log('heeee')
                this.currentBar = cursorPosition[0].index
                this.activeColor = cursorPosition[0].element.options.backgroundColor;
                this.activeHeight = cursorPosition[0].element.height;
                this.activeWidth = cursorPosition[0].element.width;
                // console.log('click event ' + this.currentBar)
            
                mousemove.target.style.cursor = 'grab';
            } else {
                mousemove.target.style.cursor = 'default';
            }
        }
    }
}
    
</script>
<style>
canvas {
  /* height: 70vh !important;  */
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.color-panel {
    display: flex;
    justify-content: space-evenly;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    width: 90%;
    margin: auto;
}

.color-box {
    width: 20px;
    height: 20px;
    cursor: pointer;
    border: 1px solid #000;
    display: inline-block;
}
.page-css {
    margin: 1rem 0;
    .store-title {
        font-size: 24px;
        font-weight: 700;
        // line-height: 29px;
        // letter-spacing: 0em;
        // text-align: center;
        // font-family: Inter;
    }
    .parent-block {
        display: flex;
        justify-content: space-between;
        justify-content: center;

        max-width: 1150px;
        margin: auto;
        @media screen and (max-width: 1150px) {
            justify-content: unset;
        }
        /* different techniques for iPad screening */
        @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
            /* For portrait layouts only */
            max-width: 1024px;
        }

        @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
            /* For landscape layouts only */
            max-width: 1024px;
        }
        .parent-left {
            width: 75%;
            min-width: 1000px;
            max-width: 1000px;
            padding: 1rem 0 2rem;
            border-radius: 36px;
            background: #FFF;
            border: 6px solid #FFD2AB;
            margin-right: 8px;
            /* different techniques for iPad screening */
            @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
                /* For portrait layouts only */
                min-width: unset;
            }

            @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
                /* For landscape layouts only */
                min-width: unset;
            }
            .unitdivl {
                display: flex;
                justify-content: flex-start;
            }
            .dl-block {
                // padding: 1.5rem 0;
                padding: 1rem 0;
                background-color: white;
            }
            .canvas-container {
                margin: 0 200px 0 65px;
                position: relative;
            }
            .parent-row {
                // width: 100%;
                display: flex;
                justify-content: flex-start;
                margin: 0 150px 0 70px;
                .row-css {
                    width: calc(100% - 27px);
                    display: flex;
                    // justify-content: space-around;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    .parent-input {
                        flex: 0 0 calc(20% - 10px); /* Set 20% width for each item with a bit of margin */
                        margin: 0px 5px 10px; /* Adjust the margin as needed */
                    }
                    .eachinput {
                        width: 120px;
                        text-align: center;
        
                        color: #000;
                        font-size: 20px;
                        font-weight: 400;
                        padding: 10px;
        
                        border-radius: 12px;
                        border: 1px solid #000;
        
                        box-sizing: border-box;
                    }
                    .color-picker {
                        width: calc(20% - 10px);
                        border-radius: 12px;
                        flex: 0 0 calc(20% - 10px);
                        margin: 5px;
                    }
                }
            }
            .unitdivr {
                display: flex;
                justify-content: flex-end;
                position: relative;
            }
            .input-parent {
                display: flex;
                justify-content: center;
                margin-bottom: 1rem;
            }
            .input-box1, .input-box2 {
                font-size: 20px;
                font-weight: 400;
                // letter-spacing: 0em;
                color: #000;
                // width: 220px;
                // border-radius: 12px;
                border: none;
                border-radius: 12px;
                // padding: 2px 0;
                // margin-top: 0;
                // height: 36px;
                // font-family: Inter;
                padding: 8px 3px;
            }
            .input-box1 {
                width: 100px;
                text-align: right;
            }
            .input-box2 {
                max-width: 40px;
                width: auto;
                text-align: center;
                padding-left: 3px;
                padding-right: 3px;
            }
            .input-titlebox {
                font-size: 20px;
                font-weight: 400;
                // letter-spacing: 0em;
                text-align: center;
                color: #000;
                width: 250px;
                border-radius: 12px;
                border: 1px solid;
                padding: 8px 0;
                // margin-top: 0;
                // height: 36px;
                // font-family: Inter;
            }
            .vertical-unit, .vertical-nounit, .horizontal-unit, .horizontal-nounit {
                font-size: 20px;
                font-weight: 400;
                letter-spacing: 0em;
                color: #000;
                border-radius: 12px;
                border: 2px;
                text-align: center;
                // line-height: 24px;
                // height: 36px;
                // margin-bottom: 0;
                // font-family: Inter;
            }
            .vertical-unit {
                border: 1px solid;
                padding: 3px 1rem;
            }
            .vertical-nounit {
                padding: 3px 1rem;
            }
            .horizontal-unit {
                border: 1px solid;
                padding: 3px 1rem;
                margin-top: 0;
                margin-bottom: 0;
            }
            .horizontal-nounit {
                padding: 0 1rem;
                position: absolute;
                bottom: 33px;
                right: -200px;
            }
            .inputBorder {
                border: 2px solid #000;
                border-radius: 12px;
            }
            .one-row {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 1rem;
                margin-top: 1.5rem;
                /* different techniques for iPad screening */
                @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
                    /* For portrait layouts only */
                    padding: 0 8px;
                }

                @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
                    /* For landscape layouts only */
                    padding: 0 8px;
                }
                .left-div {
                    display: flex;
                    align-items: center;
                    .parent-bg {
                        background: #F11154;
                        border-radius: 36px;
                        margin-right: 5px;
                        width: 140px;
                    }
                    .input-img {
                        width: 155px;
                        height: 64px;
                        margin-right: 5px;
                        /* different techniques for iPad screening */
                        @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
                            /* For portrait layouts only */
                            margin-right: 3px;
                        }

                        @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
                            /* For landscape layouts only */
                            margin-right: 3px;
                        }
                    }
                    .shownum {
                        width: 140px;
                        height: 64px;
                        margin-right: 5px;
                        /* different techniques for iPad screening */
                        @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
                            /* For portrait layouts only */
                            margin-right: 3px;
                        }

                        @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
                            /* For landscape layouts only */
                            margin-right: 3px;
                        }
                    }
                    .data1 {
                        padding: 0 5px;
                        height: 51px;
                        line-height: 51px;
                        border-radius: 36px;
                        font-size: 24px;
                        font-weight: 700;
                        letter-spacing: 0em;
                        color: #FFF;
                        margin: 4px 5px 10px;

                        text-align: center;
                        background: #FF6D6D;
                        cursor: pointer;
                    }
                    .parent2-bg {
                        background: #2954EE;
                        border-radius: 36px;
                        margin-right: 5px;
                        width: 140px;
                    }
                    .data2 {
                        padding: 0 5px;
                        height: 64px;
                        line-height: 64px;
                        height: 51px;
                        line-height: 51px;
                        border-radius: 36px;
                        font-size: 24px;
                        font-weight: 700;
                        letter-spacing: 0em;
                        color: #FFF;
                        margin: 4px 5px 10px;

                        background: #6486FF;
                        cursor: pointer;
                    }
                    .data3 {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                    }
                    .imgcss {
                        width: 95px;
                        height: 64px;
                    }
                }
                .right-div {
                    display: flex;
                    align-items: center;
                    .data4 {
                        padding: 0 2rem;
                        height: 64px;
                        line-height: 64px;
                        border-radius: 36px;
                        font-size: 24px;
                        font-weight: 700;
                        letter-spacing: 0em;
                        color: #FFF;
                        margin-right: 5px;

                        text-align: center;
                        background: #FF9C64;
                        cursor: pointer;
                    }
                    .randomcss {
                        width: 140px;
                        height: 64px;
                        margin-right: 5px;
                        /* different techniques for iPad screening */
                        @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
                            /* For portrait layouts only */
                            margin-right: 3px;
                        }

                        @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
                            /* For landscape layouts only */
                            margin-right: 3px;
                        }
                    }
                    .removecss, .cameracss {
                        width: 62px;
                        height: 62px;
                        margin-right: 5px;
                        cursor: pointer;
                        /* different techniques for iPad screening */
                        @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
                            /* For portrait layouts only */
                            margin-right: 3px;
                        }

                        @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
                            /* For landscape layouts only */
                            margin-right: 3px;
                        }
                    }
                }
            }
        }
    }
}
</style>
<style scoped>
    input:hover,
    input:focus {
      border: none;
      outline: none;
    }

    /* https://stackoverflow.com/questions/11167281/webkit-css-to-control-the-box-around-the-color-in-an-inputtype-color */
    input[type="color"] {
      -webkit-appearance: none;
      border: none;
      /* border: 1px solid #000; */
      width: 43px;
      height: 43px;

      border-radius: 5px;
      padding: 5px;
      appearance: none; /* Remove default styling on some browsers */
      -webkit-appearance: none; /* For WebKit browsers like Chrome and Safari */
    }
    input[type="color"]::-webkit-color-swatch-wrapper {
      padding: 0;
      border: 2px solid #ccc;
      border-radius: 5px;
    }
    input[type="color"]::-webkit-color-swatch {
      border: none;
      border-radius: 3px; /* Adjust the border-radius for the color representation box */
    }

    /* 
    chartjs-plugin-datalabels

    https://github.com/chartjs/chartjs-plugin-datalabels

    https://chartjs-plugin-datalabels.netlify.app/samples/charts/bar.html 
    
    Show Count on top of the bar graph - ChartJS
    https://stackoverflow.com/questions/61129220/show-count-on-top-of-the-bar-graph-chartjs
    */
</style>
